import React, {Component} from 'react';
import {connect} from "react-redux";
import './breadcrumbs.css';
import {Link} from "react-router-dom";

class Breadcrumbs extends Component {

    render() {

        let breadcrumbs = [];

        if (this.props.name) {
            switch (this.props.who) {
                case "medicine_details":
                    breadcrumbs.push(
                        <li>
                            <Link to="/medicines">{this.props.breadcrumbs[this.props.who][this.props.language]}</Link>
                        </li>
                    );
                    break;
                case "medical_institution_details":
                    breadcrumbs.push(
                        <li>
                            <Link to="/medical_institutions">{this.props.breadcrumbs[this.props.who][this.props.language]}</Link>
                        </li>
                    );
                    break;
            }
            breadcrumbs.push(
                <li>
                    {this.props.name}
                </li>
            );
        } else {
            if(this.props.breadcrumbs[window.location.pathname.split('/')[1]]){
                breadcrumbs.push(
                    <li>
                        {this.props.breadcrumbs[window.location.pathname.split('/')[1]][this.props.language]}
                    </li>
                )
            }
        }

        return (
            <ul className="breadcrumb">
                <li>
                    <Link to={this.props.breadcrumbs.home_url[this.props.language]}><span className="glyphicon glyphicon-home"></span></Link>
                </li>
                {breadcrumbs}
            </ul>
        )
    }
}

const mapStateToProps = (state) => ({
    breadcrumbs: state.searchReducer.breadcrumbs,
    language: state.languageReducer,
});

export default connect(mapStateToProps)(Breadcrumbs);