import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import './header.css';
import {setLanguage} from '../../Actions/simpleActions';

class Header extends Component {

    render() {
        let header = [];

        for(let item in this.props.header){
            let element = this.props.header[item];

            if(!element.hasSubMenu){
                header.push(
                    <li>
                        <Nav.Link href={element.url[this.props.language]}>{element[this.props.language]}</Nav.Link>
                    </li>
                );
            } else {

                let subMenu = [];

                for(let item in element){
                    if (typeof element[item] === "object" ){
                        subMenu.push(
                            <li>
                                <NavDropdown.Item href={element[item].url[this.props.language]}>
                                    {element[item][this.props.language]}
                                </NavDropdown.Item>
                            </li>
                        );
                    }
                }

                header.push(
                    <li className="dropdown">
                        <NavDropdown title={
                            <div>
                                {element[this.props.language]}
                                <span className="caret"></span>
                            </div>
                        }>
                            <ul className="subMenu">
                                {subMenu}
                            </ul>
                        </NavDropdown>
                    </li>
                )
            }
        }

        let languageMenu;

        switch (this.props.language) {
            case 'en':
                languageMenu =
                    <ul className="subMenu">
                        <li onClick={() => this.props.setLang('ru')}>
                            <NavDropdown.Item> Ru </NavDropdown.Item>
                        </li>
                        <li onClick={() => this.props.setLang('az')}>
                            <NavDropdown.Item> Az </NavDropdown.Item>
                        </li>
                    </ul>
                break;
            case 'ru':
                languageMenu =
                    <ul className="subMenu">
                        <li onClick={() => this.props.setLang('az')}>
                            <NavDropdown.Item> Az </NavDropdown.Item>
                        </li>
                        <li onClick={() => this.props.setLang('en')}>
                            <NavDropdown.Item> En </NavDropdown.Item>
                        </li>
                    </ul>
                break;
            case 'az':
                languageMenu =
                    <ul className="subMenu">
                        <li onClick={() => this.props.setLang('en')}>
                            <NavDropdown.Item> En </NavDropdown.Item>
                        </li>
                        <li onClick={() => this.props.setLang('ru')}>
                            <NavDropdown.Item> Ru </NavDropdown.Item>
                        </li>
                    </ul>
                break;
        }

        return(
            <Nav className="navbar navbar-default navbar-fixed-top">
                <Container fluid>
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-main" aria-expanded="false">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <Navbar.Brand href={this.props.breadcrumbs.home_url[this.props.language]}>
                            <img src="/assets/imgs/logo.png" height="36"/>
                        </Navbar.Brand>
                    </div>
                    <div id="navbar-main" className="navbar-collapse collapse">
                        <ul className="nav navbar-nav">
                            {header}
                        </ul>
                        <ul className="nav navbar-nav">
                            <li className="dropdown">
                                <NavDropdown title={
                                    <div>
                                        {this.props.language[0].toUpperCase() + this.props.language[1]}
                                        <span className="caret"></span>
                                    </div>
                                }>
                                    {languageMenu}
                                </NavDropdown>
                            </li>
                        </ul>
                    </div>
                </Container>
            </Nav>
        )
    }
}

const mapStateToProps = (state) => ({
    header: state.mainReducer.dictionary.header,
    breadcrumbs: state.searchReducer.breadcrumbs,
    language: state.languageReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLang: (language) => {
            dispatch(setLanguage(language));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);