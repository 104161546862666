import React, {Component} from 'react';
import {connect} from "react-redux";
import FindForm from "../form/find_form";
import ResultsTable from "./table";
import Breadcrumbs from "../breadcrumbs/breadcrumbs";
import {setActive, setFilter, setKind, setLoaderStatus} from "../../Actions/simpleActions";
import {getMedicines, getMedicinesCount} from "../../Actions/medicinesActions";
import {getPharmaCompanies, getPharmaCompaniesCount} from "../../Actions/pharmaCompaniesActions";
import {getMedicalInstitutions, getMedicalInstitutionsCount} from "../../Actions/medicalInstitutionsActions";

class SearchPage extends Component {

    constructor(props){
        super(props);
        this.state = {
            Name: this.props.search.filters.Name,
            Address: this.props.search.filters.Address,
            ActualAddress: this.props.search.filters.ActualAddress,
            Kind: this.props.search.filters.Kind || this.props.Kind,
            Composition: this.props.search.filters.Composition,
            Manufacturer: this.props.search.filters.Manufacturer,
            CertificateNumber: this.props.search.filters.CertificateNumber,
            Registration: this.props.search.filters.Registration,
            FacilityType: this.props.search.filters.FacilityType,
            ITN: this.props.search.filters.ITN,
            active_page: this.props.search.filters.active_page
        };
    }

    componentWillMount() {
        this.props.setLoaderStatus();
        this.props.setKind(this.state.Kind);
        switch (this.props.who) {
            case 'medical_institutions':
                this.props.getMedicalInstitutions(1, this.state);
                break;
            case 'medicines':
                this.props.getMedicines(1, this.state);
                break;
            case 'pharma_companies':
                this.props.getPharmaCompanies(1, this.state);
                break;
        }
    }

    render() {
        return (
            <div className="container" style={{marginTop: '45px'}}>
                <div className="row">
                    <div className="col-sm-12">
                        <Breadcrumbs />
                        <h2>{this.props.header[this.props.who].search_for[this.props.language]}</h2>
                        <hr/>
                        <div className="col-lg-12">
                            <FindForm who={this.props.who} Kind={this.state.Kind}/>
                        </div>
                        <h4>{this.props.header.searching_results[this.props.language]}</h4>
                        <hr/>
                        <div className="col-lg-12">
                            <ResultsTable who={this.props.who}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    header: state.mainReducer.dictionary.searchPages,
    language: state.languageReducer,
    search: state.searchReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getMedicines: (active_page, filter) => {
            if(
                filter.CertificateNumber.trim().length !== 0 ||
                filter.Name.trim().length !== 0 ||
                filter.Composition.trim().length !== 0 ||
                filter.Manufacturer.trim().length !== 0
            ) {
                dispatch(setLoaderStatus(true));
                dispatch(setActive(active_page));
                dispatch(setFilter(filter));
                dispatch(getMedicines(active_page, filter));
                dispatch(getMedicinesCount(filter));
            }
        },
        getPharmaCompanies: (active_page, filter) => {
            if(
                filter.Name.trim().length !== 0 ||
                filter.Address.trim().length !== 0 ||
                filter.Registration.trim().length !== 0 ||
                filter.ITN.trim().length !== 0
            ) {
                dispatch(setLoaderStatus(true));
                dispatch(setActive(active_page));
                dispatch(setFilter(filter));
                dispatch(getPharmaCompanies(active_page, filter));
                dispatch(getPharmaCompaniesCount(filter));
            }
        },
        getMedicalInstitutions: (active_page, filter) => {
            if(
                filter.Name.trim().length !== 0 ||
                filter.ActualAddress.trim().length !== 0 ||
                filter.FacilityType.trim().length !== 0
            ){
                dispatch(setActive(active_page));
                dispatch(setFilter(filter));
                dispatch(getMedicalInstitutions(active_page, filter));
                dispatch(getMedicalInstitutionsCount(filter));
            }
        },
        setLoaderStatus: () => {
            dispatch(setLoaderStatus(false));
        },
        setKind: (Kind) => {
            dispatch(setKind(Kind));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);