import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';

export const SET_PHARMA_COMPANIES = 'SET_PHARMA_COMPANIES';
export const SET_PHARMA_COMPANIES_COUNT = 'SET_PHARMA_COMPANIES_COUNT';
export const SET_PHARMA_COMPANIES_FOR_AUTOCOMPLETE = 'SET_PHARMA_COMPANIES_FOR_AUTOCOMPLETE';

function setPharmaCompanies(items) {
    return {
        type: SET_PHARMA_COMPANIES,
        payload: items
    }
}

export function getPharmaCompanies(active_page, filter) {
    return function (dispatch) {
        (async () => {
            let response = await fetch('/api/pharma-companies/extened-find?_limit=10&_sort=Name&_start=' + (active_page - 1) * 10 +
                (filter.Name && (`&Name=%25${filter.Name.replace(/%/g,'\\%25')}%25`)) +
                (filter.Address && (`&Address=%25${filter.Address.replace(/%/g,'\\%25')}%25`)) +
                (filter.Registration && (`&Registration=%25${filter.Registration.replace(/%/g,'\\%25')}%25`)) +
                (filter.ITN && (`&ITN=${filter.ITN}`)));

            if (response.ok) {
                dispatch(setPharmaCompanies(await response.json()));
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        })();
    }
}

function setPharmaCompaniesCount(items_count) {
    return {
        type: SET_PHARMA_COMPANIES_COUNT,
        payload: items_count
    }
}

export function getPharmaCompaniesCount(filter) {
    return function (dispatch) {
        (async () => {
            let response = await fetch('/api/pharma-companies/extened-count?' +
                (filter.Name && (`&Name=%25${filter.Name.replace(/%/g,'\\%25')}%25`)) +
                (filter.Address && (`&Address=%25${filter.Address.replace(/%/g,'\\%25')}%25`)) +
                (filter.Registration && (`&Registration=%25${filter.Registration.replace(/%/g,'\\%25')}%25`)) +
                (filter.ITN && (`&ITN=${filter.ITN}`)));

            if (response.ok) {
                dispatch(setPharmaCompaniesCount((await response.json())));
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        })();
    }
}

function setPharmaCompaniesForAutocomplete(items) {
    return {
        type: SET_PHARMA_COMPANIES_FOR_AUTOCOMPLETE,
        payload: items
    }
}

export function getPharmaCompaniesForAutocomplete(sort_by, filter) {
    return function (dispatch) {
        (async () => {
            let response = await fetch('/api/pharma-companies/extened-find?_limit=10' +
                (sort_by && ('&OrderBy=' + sort_by)) +
                (sort_by && ('&Distinct=' + sort_by)) +
                (filter.Name && (`&Name=%25${filter.Name.replace(/%/g,'\\%25')}%25`)) +
                (filter.Address && (`&Address=%25${filter.Address.replace(/%/g,'\\%25')}%25`)) +
                (filter.Registration && (`&Registration=%25${filter.Registration.replace(/%/g,'\\%25')}%25`)) +
                (filter.ITN && (`&ITN=${filter.ITN}`)));

            if (response.ok) {
                dispatch(setPharmaCompaniesForAutocomplete((await response.json())));
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        })();
    }
}