import React, {Component} from 'react';
import {connect} from "react-redux";

class TableHeader extends Component {
    render() {

        let headers = [<th style={{borderTop: 'none'}}>#</th>];

        for(let item in this.props.items){
            headers.push(
                <th style={{borderTop: 'none'}}>{this.props.items[item][this.props.language]}</th>
            );
        }

        return (
            <tr>
                {headers}
            </tr>
        );
    }
}

const mapStateToProps = (state) => ({
    language: state.languageReducer
});

export default connect(mapStateToProps)(TableHeader);