import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return(
            <div>
                footer
            </div>
        )
    }
}

export default Footer;