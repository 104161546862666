import React, {Component} from 'react';
import TableHeader from "./table_header";
import ResultRows from "./result_rows";
import Table from 'react-bootstrap/Table';
import './table.css';
import {connect} from "react-redux";
import MyPagination from "../pagination/pagination";
import Loader from 'react-loader-spinner';

class ResultsTable extends Component {
    render() {
        if (!this.props.search.loaderStatus) {
            return (
                <div>
                    <Table responsive size="md">
                        <thead>
                        <TableHeader items={this.props.table[this.props.who].table_header_texts} who={this.props.who}/>
                        </thead>
                        <tbody>
                        {this.props.search.all_finds.map((item, i) => <ResultRows key={i} index={(this.props.search.filters.active_page - 1) * 10 + i} element={item} who={this.props.who}/>)}
                        </tbody>
                    </Table>
                    <div className="row">
                        <MyPagination who={this.props.who}/>
                    </div>
                </div>
            );
        } else {
            return (
                <Loader
                    type="Oval"
                    color="gray"
                    height={100}
                    width={100}
                />
            );
        }
    }
}

const mapStateToProps = (state) => ({
    table: state.mainReducer,
    language: state.languageReducer,
    search: state.searchReducer
});

export default connect(mapStateToProps)(ResultsTable);
