import React, {Component} from 'react';
import {connect} from "react-redux";
import './homePage.css';
import {changeActiveTab, setFilter} from '../../Actions/simpleActions';
import {getMedicinesForAutocomplete} from "../../Actions/medicinesActions";
import {Typeahead} from "react-bootstrap-typeahead";
import {Button, Nav} from "react-bootstrap";
import {getMedicalInstitutionsForAutocomplete} from "../../Actions/medicalInstitutionsActions";
import {getPharmaCompaniesForAutocomplete} from "../../Actions/pharmaCompaniesActions";
import {Link, withRouter} from "react-router-dom";
import $ from 'jquery';
import GoogleMapReact from 'google-map-react';
import 'whatwg-fetch';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class HomePage extends Component {
    static defaultProps = {
        center: {
          lat: 40.383,
          lng: 49.830
        },
        zoom: 15
    }

    top() {
        fetch('/top.php').then(response => {
            if (response.ok) {
                return response.text();
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        }).then((top) => {
            this.setState({top});
            setTimeout(() => {$('#myCarousel').carousel();});
        });
    }

    download(lang, code) {
        fetch('/bottom.php?lang=' + code).then(response => {
            if (response.ok) {
                return response.text();
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        }).then((bottom) => {
            let mod = {};
            mod['bottom_'+lang] = bottom;
            this.setState(mod);
        });

    }

    constructor(props) {
        super(props);
        this.state = {
            Name: this.props.search.filters.Name,
            Address: this.props.search.filters.Address,
            ActualAddress: this.props.search.filters.ActualAddress,
            Kind: this.props.search.filters.Kind,
            Composition: this.props.search.filters.Composition,
            Manufacturer: this.props.search.filters.Manufacturer,
            CertificateNumber: this.props.search.filters.CertificateNumber,
            Registration: this.props.search.filters.Registration,
            FacilityType: this.props.search.filters.FacilityType,
            ITN: this.props.search.filters.ITN,
            active_page: this.props.search.filters.active_page,
            activeTab: this.props.searchTabs.medicines.active ? 'medicines' :
                            this.props.searchTabs.pharma_companies.active ? 'pharma_companies' :
                                 this.props.searchTabs.medical_institutions.active ? 'medical_institutions' : ''

        };

        this.handleChange = this.handleChange.bind(this);
        this.changeActiveTab = this.changeActiveTab.bind(this);
        this.top();
        this.download('az', 1);
        this.download('ru', 2);
        this.download('en', 3);
    }

    handleChange(item, value, cat) {

        var newState;

        if(cat){
            newState = {
                ...this.state,
                Name: '',
                [item]: value
            }
        } else {
            newState = {
                ...this.state,
                [item]: value
            };

            switch (this.state.activeTab) {
                case 'medicines':
                    this.props.getMedicinesForAutocomplete(item, newState);
                    break;
                case 'pharma_companies':
                    this.props.getPharmaCompaniesForAutocomplete(item, newState);
                    break;
                case 'medical_institutions':
                    this.props.getMedicalInstitutionsForAutocomplete(item, newState);
                    break;
            }
        }

        this.setState(newState);
    }

    changeActiveTab(event) {
        this.handleChange('activeTab', event.target.dataset.target, true);
        this.props.changeActiveTab(event.target.dataset.target);
    }

    render() {

        let searchTabs = [];

        for(let item in this.props.searchTabs){
            searchTabs.push(
                <li className={this.props.searchTabs[item].active ? 'active' : ''}>
                    <Nav.Link onClick={(event) => this.changeActiveTab(event)} data-target={item}>{this.props.searchTabs[item][this.props.language]}</Nav.Link>
                </li>
            )
        }

        let label;

        switch (this.state.activeTab) {
            case 'medicines':
                label = 'Dərmanın Adı';
                break;
            case 'pharma_companies':
                label = 'Aptekin Adı';
                break;
            case 'medical_institutions':
                label = 'Avadanlıqın Adı';
                break;
        }

        return (
            <div style={{marginTop: '45px'}}>
                <div dangerouslySetInnerHTML={{__html: this.state.top}} />
                <div className="container" >
                    <div className="fluid_container">
                        <div className="container" style={{marginTop: '30px'}}>
                            <ul className="nav nav-tabs">
                                {searchTabs}
                            </ul>
                            <div id="myTabContent" className="tab-content">

                                <div className="tab-pane fade active in">
                                    <form className="form-horizontal">
                                        <div className="form-group col-lg-11">
                                            <label htmlFor="frm2_name" className="col-lg-2 control-label">{label}</label>
                                            <div className="col-lg-10">
                                                <Typeahead
                                                    defaultInputValue={this.state.Name}
                                                    className="not_show"
                                                    multiple={false}
                                                    labelKey={
                                                        (value) => {
                                                            if(value.hasOwnProperty('Name')) return value['Name'];
                                                            else return '';
                                                        }
                                                    }
                                                    onFocus={(value) => this.handleChange('Name', value.target.value)}
                                                    onChange={
                                                        (value) => {
                                                            if (value[0]) this.handleChange('Name', value[0]['Name']);
                                                        }
                                                    }
                                                    onInputChange={(value) => this.handleChange('Name', value)}
                                                    onKeyDown={
                                                        (event) => {
                                                            if (event.keyCode === 13){
                                                                setTimeout(() => {
                                                                    this.props.history.push(`/${this.state.activeTab}`)
                                                                })
                                                            }
                                                        }
                                                    }
                                                    options={this.props.search.autocomplites}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-lg-1">
                                            <Button className="mainSearch"><Link to={`/${this.state.activeTab}`}>{this.props.forms.find[this.props.language]}</Link></Button>
                                        </div>
                                    </form>
                                    <p></p>
                                </div>

                                <div className="tab-pane fade" id="aptekler">
                                    <p>
                                    </p>
                                    <form className="form-horizontal">
                                        <fieldset>
                                            <div className="form-group col-lg-11">
                                                <label htmlFor="inputaptekname" className="col-lg-2 control-label">Aptekin
                                                    adı</label>
                                                <div className="col-lg-10">
                                                    <input type="text" className="form-control" id="inputaptekname" placeholder="Aptekin adı" />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <div className="col-lg-12 col-lg-offset-1">
                                                    <button type="submit" className="btn btn-primary">Axtar</button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                    <p></p>
                                </div>

                                <div className="tab-pane fade" id="medorgs">
                                    <p>
                                    </p>
                                    <form className="form-horizontal">
                                        <fieldset>
                                            <div className="form-group col-lg-11">
                                                <label htmlFor="inputmedorg" className="col-lg-2 control-label">Tibb
                                                    muəssisəsinin adı</label>
                                                <div className="col-lg-10">
                                                    <input type="text" className="form-control" id="inputmedorg" placeholder="Tibb muəssisəsinin adı" />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <div className="col-lg-10 col-lg-offset-2">
                                                    <button type="submit" className="btn btn-primary">Axtar</button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                    <p></p>
                                </div>

                                <div className="tab-pane fade" id="medava">
                                    <p>
                                    </p>
                                    <form className="form-horizontal">
                                        <fieldset>
                                            <div className="form-group col-lg-11">
                                                <label htmlFor="inputmedorg" className="col-lg-2 control-label">Avadanlıqın
                                                    adı</label>
                                                <div className="col-lg-10">
                                                    <input type="text" className="form-control" id="inputmedorg" placeholder="Avadanlıqın adı" />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <div className="col-lg-10 col-lg-offset-2">
                                                    <button type="submit" className="btn btn-primary">Axtar</button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: this.state['bottom_' + this.props.language]}} />
                    </div>
                </div>
                <div style={{ height: '300px', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyCZIzcbvWhYrzlDsJn0-2i-JiuQM3Fofq8' }}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                    >
                        <AnyReactComponent
                            lat={this.props.center.lat}
                            lng={this.props.center.lng}
                            text="OUR ADDRESS"
                        />
                    </GoogleMapReact>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    searchTabs: state.mainReducer.mainPage.searchTabs,
    forms: state.mainReducer.dictionary.searchPages,
    language: state.languageReducer,
    search: state.searchReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        getMedicinesForAutocomplete: (sort_by, filter) => {
            if(filter.Name.trim().length) {
                dispatch(setFilter(filter));
                dispatch(getMedicinesForAutocomplete(sort_by, filter))
            }
        },
        getPharmaCompaniesForAutocomplete: (sort_by, filter) => {
            if(filter.Name.trim().length) {
                dispatch(setFilter(filter));
                dispatch(getPharmaCompaniesForAutocomplete(sort_by, filter));
            }
        },
        getMedicalInstitutionsForAutocomplete: (sort_by, filter) => {
            if(filter.Name.trim().length) {
                dispatch(setFilter(filter));
                dispatch(getMedicalInstitutionsForAutocomplete(sort_by, filter))
            }
        },
        changeActiveTab: (active_tab) => {
            dispatch(changeActiveTab(active_tab));
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));
