import React, {Component} from 'react';
import {connect} from "react-redux";
import './result_rows.css';
import {Link} from "react-router-dom";

class ResultRows extends Component {
    render() {
        let rowData = [];

        if(!this.props.element.error){

            switch (this.props.who) {
                case 'medical_institutions':
                    return (
                        <tr>
                            <td>{this.props.index + 1}</td>
                            <td>{this.props.element.Name}</td>
                            <td>{this.props.element.ActualAddress}</td>
                            <td>{this.props.type[this.props.element.Kind][this.props.language]}</td>
                            <td><Link to={`/medical_institution_details/${this.props.element.id}`}><span className="glyphicon glyphicon-eye-open"></span></Link></td>
                        </tr>
                    );

                case 'medicines':
                    return (
                        <tr>
                            <td>{this.props.index + 1}</td>
                            <td>{this.props.element.CertificateNumber ? this.props.element.CertificateNumber.replace(/№| |DV|N/g, '') : ''}</td>
                            <td>{this.props.element.Name}</td>
                            <td>{this.props.element.Composition}</td>
                            <td>{this.props.element.Manufacturer}</td>
                            <td><Link to={`/medicine_details/${this.props.element.Uuid}`}><span className="glyphicon glyphicon-eye-open"></span></Link></td>
                        </tr>
                    );

                case 'pharma_companies':
                    return (
                        <tr>
                            <td>{this.props.index + 1}</td>
                            <td>{this.props.element.Name}</td>
                            <td>{this.props.element.Address}</td>
                            <td>{this.props.element.Registration}</td>
                            <td>{this.props.element.ITN}</td>
                        </tr>
                    );

                case 'medicine_details':
                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medicine_details.Name[this.props.language]}</td>
                            <td>:</td>
                            <td>{this.props.element.Name}</td>
                        </tr>
                    );
                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medicine_details.Composition[this.props.language]}</td>
                            <td>:</td>
                            <td>{this.props.element.Composition}</td>
                        </tr>
                    );
                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medicine_details.Package[this.props.language]}</td>
                            <td>:</td>
                            <td>{this.props.element.Package}</td>
                        </tr>
                    );
                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medicine_details.Applicant[this.props.language]}</td>
                            <td>:</td>
                            <td>{this.props.element.Applicant}</td>
                        </tr>
                    );
                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medicine_details.Manufacturer[this.props.language]}</td>
                            <td>:</td>
                            <td>{this.props.element.Manufacturer}</td>
                        </tr>
                    );
                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medicine_details.CertificateNumber[this.props.language]}</td>
                            <td>:</td>
                            <td>{('' + this.props.element.CertificateNumber).replace(/№| |DV|N/g, '')}</td>
                        </tr>
                    );
                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medicine_details.CertificateFrom[this.props.language]}</td>
                            <td>:</td>
                            <td>{this.props.element.CertificateFrom}</td>
                        </tr>
                    );
                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medicine_details.CertificateTo[this.props.language]}</td>
                            <td>:</td>
                            <td>{this.props.element.CertificateTo}</td>
                        </tr>
                    );
                    if (this.props.element.leafletExists) {
                        rowData.push(
                            <tr>
                                <td className="medicine_details">{this.props.medicine_details.instructions[this.props.language]}</td>
                                <td>:</td>
                                <td><a target="_blank" href={`/api/drugs/leaflet?Uuid=${this.props.element.Uuid}`}>{('' + this.props.element.CertificateNumber).replace(/№| |DV|N/g, '')}</a></td>
                            </tr>
                        );
                    }
                    return rowData;

                case 'medical_institution_details':
                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medical_institution_details.Name[this.props.language]}</td>
                            <td>:</td>
                            <td>{this.props.element.Name}</td>
                        </tr>
                    );
                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medical_institution_details.ActualAddress[this.props.language]}</td>
                            <td>:</td>
                            <td>{this.props.element.ActualAddress}</td>
                        </tr>
                    );
                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medical_institution_details.Kind[this.props.language]}</td>
                            <td>:</td>
                            <td>{this.props.type[this.props.element.Kind][this.props.language]}</td>
                        </tr>
                    );
                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medical_institution_details.created_at[this.props.language]}</td>
                            <td>:</td>
                            <td>{this.props.element.created_at}</td>
                        </tr>
                    );
                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medical_institution_details.updated_at[this.props.language]}</td>
                            <td>:</td>
                            <td>{this.props.element.updated_at}</td>
                        </tr>
                    );

                    let types = [];

                    for(let item of this.props.element.FacilityType){
                        types.push(`, ${this.props.medical_institution_details.FacilityType[item.Type][this.props.language]}`);
                    }

                    types[0] = types[0].replace(',', '');

                    rowData.push(
                        <tr>
                            <td className="medicine_details">{this.props.medical_institution_details.FacilityType[this.props.language]}</td>
                            <td>:</td>
                            <td>{types}</td>
                        </tr>
                    );
                    return rowData;
            }
        } else {
            return(
                <div style={{fontSize: '32px'}}>
                    {this.props.empty_search_result[this.props.language]}
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    type: state.mainReducer.medical_institutions.find_form.Kind.options,
    medicine_details: state.mainReducer.medicines.details,
    medical_institution_details: state.mainReducer.medical_institutions.details,
    empty_search_result: state.mainReducer.dictionary.searchPages.empty_search_result,
    language: state.languageReducer
});

export default connect(mapStateToProps)(ResultRows);