import {
    CHANGE_ACTIVE_TAB,
    SET_ACTIVE,
    SET_FACILITY_TYPE,
    SET_FILTER,
    SET_KIND,
    SET_LANGUAGE,
    SET_LOADER_STATUS,
} from './Actions/simpleActions';
import {
    SET_MEDICAL_INSTITUTION_DETAILS,
    SET_MEDICAL_INSTITUTIONS,
    SET_MEDICAL_INSTITUTIONS_COUNT,
    SET_MEDICAL_INSTITUTIONS_FOR_AUTOCOMPLETE
} from './Actions/medicalInstitutionsActions';
import {
    SET_MEDICINE_DETAILS,
    SET_MEDICINES,
    SET_MEDICINES_COUNT,
    SET_MEDICINES_FOR_AUTOCOMPLETE
} from './Actions/medicinesActions';
import {
    SET_PHARMA_COMPANIES,
    SET_PHARMA_COMPANIES_COUNT,
    SET_PHARMA_COMPANIES_FOR_AUTOCOMPLETE
} from './Actions/pharmaCompaniesActions';
import {combineReducers} from "redux";

const MAIN_INITIAL_STATE = {
    mainPage:{
        searchTabs:{
            medicines:{
               en:'Medicines',
               ru:'Лекарственные средства',
               az:'Dərmanlar',
                active: true
            },
            pharma_companies:{
                en:'Pharmacies',
                ru:'Аптеки',
                az:'Apteklər',
                active: false
            },
            medical_institutions:{
                en:'Medical institutions',
                ru:'Мед. учреждения',
                az:'Tibb muəssisəsilər',
                active: false
            },
            /*equipment:{
                en:'Equipments',
                ru:'Оборудования',
                az:'Avadanlıqlar',
                active: false
            }*/
        }
    },
    medical_institutions:{
        table_header_texts: {
            Name: {
                en: 'Name of legal entity or individual',
                ru: 'Наименование юридического или физического лица',
                az: 'Hüquqi və ya fiziki şəxsin adı'
            },
            ActualAddress: {
                en: 'Activity address',
                ru: 'Адрес ведения деятельности',
                az: 'Fəaliyyət ünvanı'
            },
            Kind: {
                en: 'Medical service',
                ru: 'Медицинская услуга',
                az: 'Tibbi xidmət'
            },
            Action:{
                en: ' ',
                ru: ' ',
                az: ' '
            }
        },
        find_form: {
            Name: {
                en: 'Name',
                ru: 'Наименование',
                az: 'Adı',
                type: 'text'
            },
            ActualAddress: {
                en: 'Activity address',
                ru: 'Адрес ведения деятельности',
                az: 'Fəaliyyət ünvanı',
                type: 'text'
            },
            Kind: {
                en: 'Medical service',
                ru: 'Медицинская услуга',
                az: 'Tibbi xidmət',
                type: 'dropdown',
                options: {
                    all: {
                        en: 'All',
                        ru: 'Все',
                        az: 'Hamısı'
                    },
                    SK: {
                        en: 'Sanatorium Resort',
                        ru: 'Санаторий Курорт',
                        az: 'Sanator-Kurort'
                    },
                    AMB: {
                        en: 'Ambulatory',
                        ru: 'Поликлиника',
                        az: 'Ambulator'
                    },
                    STAS: {
                        en: 'Stationary',
                        ru: 'Стационарный',
                        az: 'Stasionar'
                    },
                    STOM: {
                        en: 'Dentists',
                        ru: 'Стоматология',
                        az: 'Stomatoloji'
                    },
                }
            },
            FacilityType:{
                en: 'Kind of activity',
                ru: 'Вид деятельности',
                az: 'Fəaliyyət növü',
                type: 'dropdown_multiselect',
                options:{
                    THERAPEUTIC:{
                        en: 'Terapevtik',
                        ru: 'Terapevtik',
                        az: 'Terapevtik',
                        selected: false,
                        value: 'THERAPEUTIC'
                    },
                    PULMONOLOGY:{
                        en: 'Pulmonoloji',
                        ru: 'Pulmonoloji',
                        az: 'Pulmonoloji',
                        selected: false,
                        value: 'PULMONOLOGY'
                    },
                    DIETETOLOGY:{
                        en: 'Diyetoloji',
                        ru: 'Diyetoloji',
                        az: 'Diyetoloji',
                        selected: false,
                        value: 'DIETETOLOGY'
                    },
                    GERONTOLOGY:{
                        en: 'Herontoloji',
                        ru: 'Herontoloji',
                        az: 'Herontoloji',
                        selected: false,
                        value: 'GERONTOLOGY'
                    },
                    CARDIOLOGY:{
                        en: 'Kardioloji',
                        ru: 'Kardioloji ',
                        az: 'Kardioloji',
                        selected: false,
                        value: 'CARDIOLOGY'
                    },
                    CASTROENTEROLOGY:{
                        en: 'Qastroenteroloji',
                        ru: 'Qastroenteroloji',
                        az: 'Qastroenteroloji',
                        selected: false,
                        value: 'CASTROENTEROLOGY'
                    },
                    NEFROLOGY:{
                        en: 'Nefroloji',
                        ru: 'Nefroloji',
                        az: 'Nefroloji',
                        selected: false,
                        value: 'NEFROLOGY'
                    },
                    ENDOKRINOLOGY:{
                        en: 'Endokrinoloji',
                        ru: 'Endokrinoloji',
                        az: 'Endokrinoloji',
                        selected: false,
                        value: 'ENDOKRINOLOGY'
                    },
                    RHEUMATOLOGY:{
                        en: 'Revmatoloji',
                        ru: 'Revmatoloji',
                        az: 'Revmatoloji',
                        selected: false,
                        value: 'RHEUMATOLOGY'
                    },
                    HEMATOLOGY:{
                        en: 'Hematoloji',
                        ru: 'Hematoloji',
                        az: 'Hematoloji',
                        selected: false,
                        value: 'HEMATOLOGY'
                    },
                    ALLERCHOLOGY:{
                        en: 'Allerqoloji',
                        ru: 'Allerqoloji',
                        az: 'Allerqoloji',
                        selected: false,
                        value: 'ALLERCHOLOGY'
                    },
                    IMMUNOLOGY:{
                        en: 'İmmunoloji',
                        ru: 'İmmunoloji',
                        az: 'İmmunoloji',
                        selected: false,
                        value: 'IMMUNOLOGY'
                    },
                    NEUROPATHOLOGY:{
                        en: 'Nevropatoloji',
                        ru: 'Nevropatoloji',
                        az: 'Nevropatoloji',
                        selected: false,
                        value: 'NEUROPATHOLOGY'
                    },
                    PSIXIATRIC:{
                        en: 'Psixiatrik',
                        ru: 'Psixiatrik',
                        az: 'Psixiatrik',
                        selected: false,
                        value: 'PSIXIATRIC'
                    },
                    NARCOLOGY:{
                        en: 'Narkoloji',
                        ru: 'Narkoloji',
                        az: 'Narkoloji',
                        selected: false,
                        value: 'NARCOLOGY'
                    },
                    DERMATOLOGY:{
                        en: 'Dermatoloji',
                        ru: 'Dermatoloji',
                        az: 'Dermatoloji',
                        selected: false,
                        value: 'DERMATOLOGY'
                    },
                    COSMETOLOGY:{
                        en: 'Kosmetoloji',
                        ru: 'Kosmetoloji',
                        az: 'Kosmetoloji',
                        selected: false,
                        value: 'COSMETOLOGY'
                    },
                    PHYSIOTHERAPY:{
                        en: 'Fizioterapevtik',
                        ru: 'Fizioterapevtik',
                        az: 'Fizioterapevtik',
                        selected: false,
                        value: 'PHYSIOTHERAPY'
                    },
                    GENERAL_SURGERY:{
                        en: 'Ümumi cərrahi',
                        ru: 'Ümumi cərrahi',
                        az: 'Ümumi cərrahi',
                        selected: false,
                        value: 'GENERAL_SURGERY'
                    },
                    NEUROSURGERY:{
                        en: 'Neyrocərrahi',
                        ru: 'Neyrocərrahi',
                        az: 'Neyrocərrahi',
                        selected: false,
                        value: 'NEUROSURGERY'
                    },
                    CARDIAC_SURGERY:{
                        en: 'Ürək-damar cərrahi',
                        ru: 'Ürək-damar cərrahi',
                        az: 'Ürək-damar cərrahi',
                        selected: false,
                        value: 'CARDIAC_SURGERY'
                    },
                    PLASTIC_SURGERY:{
                        en: 'Plastik cərrahi',
                        ru: 'Plastik cərrahi',
                        az: 'Plastik cərrahi',
                        selected: false,
                        value: 'PLASTIC_SURGERY'
                    },
                    CHILDREN_SURGERY:{
                        en: 'Uşaq cərrahi',
                        ru: 'Uşaq cərrahi',
                        az: 'Uşaq cərrahi',
                        selected: false,
                        value: 'CHILDREN_SURGERY'
                    },
                    UROLOGY:{
                        en: 'Uroloji',
                        ru: 'Uroloji',
                        az: 'Uroloji',
                        selected: false,
                        value: 'UROLOGY'
                    },
                    ORTHOPEDIC_TRAUMATOLOGY:{
                        en: 'Travmatoloji-ortopedik',
                        ru: 'Travmatoloji-ortopedik',
                        az: 'Travmatoloji-ortopedik',
                        selected: false,
                        value: 'ORTHOPEDIC_TRAUMATOLOGY'
                    },
                    ANESTHESIOLOGY_REANIMATOLOGY:{
                        en: 'Anestezioloji-reanimatoloji',
                        ru: 'Anestezioloji-reanimatoloji',
                        az: 'Anestezioloji-reanimatoloji',
                        selected: false,
                        value: 'ANESTHESIOLOGY_REANIMATOLOGY'
                    },
                    TOXICOLOGY:{
                        en: 'Toksikoloji',
                        ru: 'Toksikoloji',
                        az: 'Toksikoloji',
                        selected: false,
                        value: 'TOXICOLOGY'
                    },
                    GYNECOLOGY:{
                        en: 'Ginekoloji (ekstrakorporal mayalandırma istisna olmaqla)',
                        ru: 'Ginekoloji (ekstrakorporal mayalandırma istisna olmaqla)',
                        az: 'Ginekoloji (ekstrakorporal mayalandırma istisna olmaqla)',
                        selected: false,
                        value: 'GYNECOLOGY'
                    },
                    MATERNITY:{
                        en: 'Doğuşa yardım',
                        ru: 'Doğuşa yardım',
                        az: 'Doğuşa yardım',
                        selected: false,
                        value: 'MATERNITY'
                    },
                    THERAPEUTIC_STOMATOLOGY:{
                        en: 'Terapevtik stomatoloji',
                        ru: 'Terapevtik stomatoloji',
                        az: 'Terapevtik stomatoloji',
                        selected: false,
                        value: 'THERAPEUTIC_STOMATOLOGY'
                    },
                    SURGICAL_STOMATOLOGY:{
                        en: 'Cərrahi stomatoloji',
                        ru: 'Cərrahi stomatoloji',
                        az: 'Cərrahi stomatoloji',
                        selected: false,
                        value: 'SURGICAL_STOMATOLOGY'
                    },
                    ORTHOPEDIC_STOMATOLOGY:{
                        en: 'Ortopedik stomatoloji',
                        ru: 'Ortopedik stomatoloji',
                        az: 'Ortopedik stomatoloji',
                        selected: false,
                        value: 'ORTHOPEDIC_STOMATOLOGY'
                    },
                    ORTHODONTIC_STOMATOLOGY:{
                        en: 'Ortodontik stomatoloji',
                        ru: 'Ortodontik stomatoloji',
                        az: 'Ortodontik stomatoloji',
                        selected: false,
                        value: 'ORTHODONTIC_STOMATOLOGY'
                    },
                    STOMATOLOGICAL_IMPLANTOLOGY:{
                        en: 'Diş texniki',
                        ru: 'Diş texniki',
                        az: 'Diş texniki',
                        selected: false,
                        value: 'STOMATOLOGICAL_IMPLANTOLOGY'
                    },
                    ORAL_AND_MAXILLOFACIAL_SURGERY: {
                        en: 'Üz-çənə cərrahi',
                        ru: 'Üz-çənə cərrahi',
                        az: 'Üz-çənə cərrahi',
                        selected: false,
                        value: 'ORAL_AND_MAXILLOFACIAL_SURGERY'
                    },
                    PEDIATRIC:{
                        en: 'Pediatrik',
                        ru: 'Pediatrik',
                        az: 'Pediatrik',
                        selected: false,
                        value: 'PEDIATRIC'
                    },
                    RENTGENOLOGY:{
                        en: 'Rentgenoloji',
                        ru: 'Rentgenoloji',
                        az: 'Rentgenoloji',
                        selected: false,
                        value: 'RENTGENOLOGY'
                    },
                    RADIOLOGY:{
                        en: 'Radioloji',
                        ru: 'Radioloji',
                        az: 'Radioloji',
                        selected: false,
                        value: 'RADIOLOGY'
                    },
                    NMR_TOMOGRAPHY:{
                        en: 'Nüvə-maqnit rezonansı',
                        ru: 'Nüvə-maqnit rezonansı',
                        az: 'Nüvə-maqnit rezonansı',
                        selected: false,
                        value: 'NMR_TOMOGRAPHY'
                    },
                    COMPUTER_TOMOGRAPHY:{
                        en: 'Kompüter tomoqrafiyası',
                        ru: 'Kompüter tomoqrafiyası',
                        az: 'Kompüter tomoqrafiyası',
                        selected: false,
                        value: 'COMPUTER_TOMOGRAPHY'
                    },
                    ULTRASOUND:{
                        en: 'Ultrasəs müayinəsi',
                        ru: 'Ultrasəs müayinəsi',
                        az: 'Ultrasəs müayinəsi',
                        selected: false,
                        value: 'ULTRASOUND'
                    },
                    OPHTHALMOLOGY:{
                        en: 'Oftalmoloji',
                        ru: 'Oftalmoloji',
                        az: 'Oftalmoloji',
                        selected: false,
                        value: 'OPHTHALMOLOGY'
                    },
                    OTORHINOLARYNGOLOGY:{
                        en: 'Otorinolarinqoloji',
                        ru: 'Otorinolarinqoloji',
                        az: 'Otorinolarinqoloji',
                        selected: false,
                        value: 'OTORHINOLARYNGOLOGY'
                    },
                    HISTOMORPHOLOGY:{
                        en: 'Histomorfoloji',
                        ru: 'Histomorfoloji',
                        az: 'Histomorfoloji',
                        selected: false,
                        value: 'HISTOMORPHOLOGY'
                    },
                    GENETIC:{
                        en: 'Genetik',
                        ru: 'Genetik',
                        az: 'Genetik',
                        selected: false,
                        value: 'HISTOMORPHOLOGY'
                    },
                    DISINFECTION:{
                        en: 'Dezinfeksiya',
                        ru: 'Dezinfeksiya',
                        az: 'Dezinfeksiya',
                        selected: false,
                        value: 'DISINFECTION'
                    },
                    DISINSECTION:{
                        en: 'Dezinseksiya',
                        ru: 'Dezinseksiya',
                        az: 'Dezinseksiya',
                        selected: false,
                        value: 'DISINSECTION'
                    },
                    DERATIZATION:{
                        en: 'Deratizasiya',
                        ru: 'Deratizasiya',
                        az: 'Deratizasiya',
                        selected: false,
                        value: 'DERATIZATION'
                    },
                    MICROBIOLOGY:{
                        en: 'Mikrobioloji',
                        ru: 'Mikrobioloji',
                        az: 'Mikrobioloji',
                        selected: false,
                        value: 'MICROBIOLOGY'
                    },
                    VIRUSOLOGY:{
                        en: 'Virusoloji',
                        ru: 'Virusoloji',
                        az: 'Virusoloji',
                        selected: false,
                        value: 'VIRUSOLOGY'
                    },
                    CLINICAL:{
                        en: 'Klinik laboratoriya',
                        ru: 'Klinik laboratoriya',
                        az: 'Klinik laboratoriya',
                        selected: false,
                        value: 'CLINICAL'
                    },
                    CLINICAL_BIOCHEMISTRY:{
                        en: 'Klinik-biokimyəvi laboratoriya',
                        ru: 'Klinik-biokimyəvi laboratoriya',
                        az: 'Klinik-biokimyəvi laboratoriya',
                        selected: false,
                        value: 'CLINICAL_BIOCHEMISTRY'
                    },
                    IMMUNOGENETIC:{
                        en: 'İmmunogenetik laboratoriya',
                        ru: 'İmmunogenetik laboratoriya',
                        az: 'İmmunogenetik laboratoriya',
                        selected: false,
                        value: 'IMMUNOGENETIC'
                    },
                }
            }
        },
        details:{
            Name:{
                en: 'Name of legal entity or individual',
                ru: 'Наименование юридического или физического лица',
                az: 'Hüquqi və ya fiziki şəxsin adı'
            },
            ActualAddress:{
                en: 'Activity address',
                ru: 'Адрес деятельности',
                az: 'Fəaliyyət ünvanı'
            },
            Kind: {
                en: 'Medical service',
                ru: 'Медицинская услуга',
                az: 'Tibbi xidmət'
            },
            created_at:{
                en: 'Created at',
                ru: 'Создан в',
                az: 'Yaradılmışdır'
            },
            updated_at:{
                en: 'Updated at',
                ru: 'Обновлено в',
                az: 'Yenilənib'
            },
            FacilityType:{
                en: 'Kind of activity',
                ru: 'Вид деятельности',
                az: 'Fəaliyyət növü',
                THERAPEUTIC:{
                    en: 'Terapevtik',
                    ru: 'Terapevtik',
                    az: 'Terapevtik',
                },
                CARDIOLOGY:{
                    en: 'Kardioloji',
                    ru: 'Kardioloji ',
                    az: 'Kardioloji',
                },
                NEUROPATHOLOGY:{
                    en: 'Nevropatoloji',
                    ru: 'Nevropatoloji',
                    az: 'Nevropatoloji',
                },
                PHYSIOTHERAPY:{
                    en: 'Fizioterapevtik',
                    ru: 'Fizioterapevtik',
                    az: 'Fizioterapevtik',
                },
                ENDOKRINOLOGY:{
                    en: 'Endokrinoloji',
                    ru: 'Endokrinoloji',
                    az: 'Endokrinoloji',
                },
                DIETETOLOGY:{
                    en: 'Diyetoloji',
                    ru: 'Diyetoloji',
                    az: 'Diyetoloji',
                },
                DERMATOLOGY:{
                    en: 'Dermatoloji',
                    ru: 'Dermatoloji',
                    az: 'Dermatoloji',
                },
                UROLOGY:{
                    en: 'Uroloji',
                    ru: 'Uroloji',
                    az: 'Uroloji',
                },
                GYNECOLOGY:{
                    en: 'Ginekoloji (ekstrakorporal mayalandırma istisna olmaqla)',
                    ru: 'Ginekoloji (ekstrakorporal mayalandırma istisna olmaqla)',
                    az: 'Ginekoloji (ekstrakorporal mayalandırma istisna olmaqla)',
                },
                THERAPEUTIC_STOMATOLOGY:{
                    en: 'Terapevtik stomatoloji',
                    ru: 'Terapevtik stomatoloji',
                    az: 'Terapevtik stomatoloji',
                },
                ORTHOPEDIC_STOMATOLOGY:{
                    en: 'Ortopedik stomatoloji',
                    ru: 'Ortopedik stomatoloji',
                    az: 'Ortopedik stomatoloji',
                },
                RENTGENOLOGY:{
                    en: 'Rentgenoloji',
                    ru: 'Rentgenoloji',
                    az: 'Rentgenoloji',
                },
                ULTRASOUND:{
                    en: 'Ultrasəs müayinəsi',
                    ru: 'Ultrasəs müayinəsi',
                    az: 'Ultrasəs müayinəsi',
                },
                OTORHINOLARYNGOLOGY:{
                    en: 'Otorinolarinqoloji',
                    ru: 'Otorinolarinqoloji',
                    az: 'Otorinolarinqoloji',
                },
                MICROBIOLOGY:{
                    en: 'Mikrobioloji',
                    ru: 'Mikrobioloji',
                    az: 'Mikrobioloji',
                },
                VIRUSOLOGY:{
                    en: 'Virusoloji',
                    ru: 'Virusoloji',
                    az: 'Virusoloji',
                },
                CLINICAL_BIOCHEMISTRY:{
                    en: 'Klinik-biokimyəvi laboratoriya',
                    ru: 'Klinik-biokimyəvi laboratoriya',
                    az: 'Klinik-biokimyəvi laboratoriya',
                },
                PSIXIATRIC:{
                    en: 'Psixiatrik',
                    ru: 'Psixiatrik',
                    az: 'Psixiatrik',
                },
                PEDIATRIC:{
                    en: 'Pediatrik',
                    ru: 'Pediatrik',
                    az: 'Pediatrik',
                },
                CASTROENTEROLOGY:{
                    en: 'Qastroenteroloji',
                    ru: 'Qastroenteroloji',
                    az: 'Qastroenteroloji',
                },
                COSMETOLOGY:{
                    en: 'Kosmetoloji',
                    ru: 'Kosmetoloji',
                    az: 'Kosmetoloji',
                },
                IMMUNOGENETIC:{
                    en: 'İmmunogenetik laboratoriya',
                    ru: 'İmmunogenetik laboratoriya',
                    az: 'İmmunogenetik laboratoriya',
                },
                NEFROLOGY:{
                    en: 'Nefroloji',
                    ru: 'Nefroloji',
                    az: 'Nefroloji',
                },
                ORTHODONTIC_STOMATOLOGY:{
                    en: 'Ortodontik stomatoloji',
                    ru: 'Ortodontik stomatoloji',
                    az: 'Ortodontik stomatoloji',
                },
                RHEUMATOLOGY:{
                    en: 'Revmatoloji',
                    ru: 'Revmatoloji',
                    az: 'Revmatoloji',
                },
                HEMATOLOGY:{
                    en: 'Hematoloji',
                    ru: 'Hematoloji',
                    az: 'Hematoloji',
                },
                ALLERCHOLOGY:{
                    en: 'Allerqoloji',
                    ru: 'Allerqoloji',
                    az: 'Allerqoloji',
                },
                GENERAL_SURGERY:{
                    en: 'Ümumi cərrahi',
                    ru: 'Ümumi cərrahi',
                    az: 'Ümumi cərrahi',
                },
                ANESTHESIOLOGY_REANIMATOLOGY:{
                    en: 'Anestezioloji-reanimatoloji',
                    ru: 'Anestezioloji-reanimatoloji',
                    az: 'Anestezioloji-reanimatoloji',
                },
                COMPUTER_TOMOGRAPHY:{
                    en: 'Kompüter tomoqrafiyası',
                    ru: 'Kompüter tomoqrafiyası',
                    az: 'Kompüter tomoqrafiyası',
                },
                OPHTHALMOLOGY:{
                    en: 'Oftalmoloji',
                    ru: 'Oftalmoloji',
                    az: 'Oftalmoloji',
                },
                PULMONOLOGY:{
                    en: 'Pulmonoloji',
                    ru: 'Pulmonoloji',
                    az: 'Pulmonoloji',
                },
                IMMUNOLOGY:{
                    en: 'İmmunoloji',
                    ru: 'İmmunoloji',
                    az: 'İmmunoloji',
                },
                SURGICAL_STOMATOLOGY:{
                    en: 'Cərrahi stomatoloji',
                    ru: 'Cərrahi stomatoloji',
                    az: 'Cərrahi stomatoloji',
                },
                NARCOLOGY:{
                    en: 'Narkoloji',
                    ru: 'Narkoloji',
                    az: 'Narkoloji',
                },
                HISTOMORPHOLOGY:{
                    en: 'Histomorfoloji',
                    ru: 'Histomorfoloji',
                    az: 'Histomorfoloji',
                },
                RADIOLOGY:{
                    en: 'Radioloji',
                    ru: 'Radioloji',
                    az: 'Radioloji',
                },
                PLASTIC_SURGERY:{
                    en: 'Plastik cərrahi',
                    ru: 'Plastik cərrahi',
                    az: 'Plastik cərrahi',
                },
                NMR_TOMOGRAPHY:{
                    en: 'Nüvə-maqnit rezonansı',
                    ru: 'Nüvə-maqnit rezonansı',
                    az: 'Nüvə-maqnit rezonansı',
                },
                DISINFECTION:{
                    en: 'Dezinfeksiya',
                    ru: 'Dezinfeksiya',
                    az: 'Dezinfeksiya',
                },
                DERATIZATION:{
                    en: 'Deratizasiya',
                    ru: 'Deratizasiya',
                    az: 'Deratizasiya',
                },
                TOXICOLOGY:{
                    en: 'Toksikoloji',
                    ru: 'Toksikoloji',
                    az: 'Toksikoloji',
                },
                NEUROSURGERY:{
                    en: 'Neyrocərrahi',
                    ru: 'Neyrocərrahi',
                    az: 'Neyrocərrahi',
                },
                CHILDREN_SURGERY:{
                    en: 'Uşaq cərrahi',
                    ru: 'Uşaq cərrahi',
                    az: 'Uşaq cərrahi',
                },
                ORTHOPEDIC_TRAUMATOLOGY:{
                    en: 'Travmatoloji-ortopedik',
                    ru: 'Travmatoloji-ortopedik',
                    az: 'Travmatoloji-ortopedik',
                },
                IN_VITRO_FERTILISATION:{
                    en: 'Ginekoloji (ekstrakorporal mayalandırma istisna olmaqla)',
                    ru: 'Ginekoloji (ekstrakorporal mayalandırma istisna olmaqla)',
                    az: 'Ginekoloji (ekstrakorporal mayalandırma istisna olmaqla)',
                },
                MATERNITY:{
                    en: 'Doğuşa yardım',
                    ru: 'Doğuşa yardım',
                    az: 'Doğuşa yardım',
                },
                CARDIAC_SURGERY:{
                    en: 'Ürək-damar cərrahi',
                    ru: 'Ürək-damar cərrahi',
                    az: 'Ürək-damar cərrahi',
                },
                CLINICAL:{
                    en: 'Klinik laboratoriya',
                    ru: 'Klinik laboratoriya',
                    az: 'Klinik laboratoriya',
                },
                DISINSECTION:{
                    en: 'Dezinseksiya',
                    ru: 'Dezinseksiya',
                    az: 'Dezinseksiya',
                },
                GENETIC:{
                    en: 'Genetik',
                    ru: 'Genetik',
                    az: 'Genetik',
                },
                ORAL_AND_MAXILLOFACIAL_SURGERY: {
                    en: 'Üz-çənə cərrahi',
                    ru: 'Üz-çənə cərrahi',
                    az: 'Üz-çənə cərrahi',
                },
                STOMATOLOGICAL_IMPLANTOLOGY:{
                    en: 'Diş texniki',
                    ru: 'Diş texniki',
                    az: 'Diş texniki',
                },
                GERONTOLOGY:{
                    en: 'Herontoloji',
                    ru: 'Herontoloji',
                    az: 'Herontoloji',
                },
            }
        }
    },
    medicines: {
        table_header_texts: {
            CertificateNumber :{
                en: 'Registration card #',
                ru: 'Номер регистрационной карточки',
                az: 'Qeydiyyat vəsiqəsinin №'
            },
            Name: {
                en: 'Patented Name',
                ru: 'Название',
                az: 'Adı'
            },
            Composition:{
                en: 'Composition',
                ru: 'Ингредиенты',
                az: 'Təsiredici maddə'
            },
            Manufacturer:{
                en: 'Manufactor',
                ru: 'Производитель',
                az: 'Istehsalçı'
            },
            Action:{
                en: ' ',
                ru: ' ',
                az: ' '
            }
        },
        find_form: {
            CertificateNumber:{
                en: 'Registration card #',
                ru: 'Номер регистрационной карточки',
                az: 'Qeydiyyat vəsiqəsinin №',
                type: 'text'
            },
            Name: {
                en: 'Patented Name',
                ru: 'Название',
                az: 'Adı',
                type: 'text'
            },
            Composition:{
                en: 'Composition',
                ru: 'Состав',
                az: 'Təsiredici maddə',
                type: 'text'
            },
            Manufacturer:{
                en: 'Manufacturer',
                ru: 'Производитель',
                az: 'Istehsalçı',
                type: 'text'
            }
        },
        details:{
            Name:{
                en: 'Name of the drug',
                ru: 'Наименование препарата',
                az: 'Dərman vasitəsinin adı'
            },
            Composition:{
                en: 'Composition',
                ru: 'Ингредиенты',
                az: 'BPA və doza'
            },
            Package:{
                en: 'Form and trade packaging',
                ru: 'Форма и торговая упаковка',
                az: 'Forması və ticarət qablaşdırması'
            },
            Applicant:{
                en: 'Applicant firm, country',
                ru: 'Фирма-заявитель, страна',
                az: 'Ərizəçi firma, ölkə'
            },
            Manufacturer:{
                en: 'Manufacturer company, country',
                ru: 'Компания-производитель, страна',
                az: 'İstehsalçı şirkət, ölkə'
            },
            CertificateNumber:{
                en: 'Registration card number',
                ru: 'Регистрационный номер карты',
                az: 'Qeydiyyat vəsiqəsinin nömrəsi'
            },
            CertificateFrom:{
                en: 'Date of issue of registration card',
                ru: 'Дата выдачи регистрационной карты',
                az: 'Qeydiyyat vəsiqəsinin verilmə tarixi'
            },
            CertificateTo:{
                en: 'Expiration date of registration',
                ru: 'Срок годности регистрации',
                az: 'Qeydiyyat müddətinin bitmə tarixi'
            },
            instructions:{
                en: 'User\'s Guide',
                ru: 'Руководство пользователя',
                az: 'İstifadə təlimatı (içlik vərəqə)'
            },
        }
    },
    pharma_companies:{
        table_header_texts: {
            Name: {
                en: 'Name',
                ru: 'Название',
                az: 'Adı'
            },
            Address:{
                en: 'Place of business',
                ru: 'Адрес ведения деятельности',
                az: 'Fəaliyyət göstərdiyi ünvan'
            },
            Registration:{
                en: 'Registration number',
                ru: 'Регистрационный номер',
                az: 'Qeydiyyat №'
            },
            ITN:{
                en: 'ITN',
                ru: 'ИНН',
                az: 'VÖEN'
            }
        },
        find_form: {
            Name: {
                en: 'Name',
                ru: 'Название',
                az: 'Adı',
                type: 'text'
            },
            Address:{
                en: 'Place of business',
                ru: 'Адрес ведения деятельности',
                az: 'Fəaliyyət göstərdiyi ünvan',
                type: 'text'
            },
            Registration:{
                en: 'Registration number',
                ru: 'Регистрационный номер',
                az: 'Qeydiyyat №',
                type: 'text'
            },
            ITN:{
                en: 'ITN',
                ru: 'ИНН',
                az: 'VÖEN',
                type: 'text'
            }
        }
    },
    dictionary:{
        header:{
            about:{
                hasSubMenu: false,
                en: 'About Us',
                ru: 'О Нас',
                az: 'Haqqımızda',
                url:{
                    en: '/index.php?lang=3&ind=main&id=70',
                    ru: '/index.php?lang=2&ind=main&id=70',
                    az: '/index.php?lang=1&ind=main&id=70',
                }
            },
            import_of_medicines:{
                hasSubMenu: true,
                en: 'Import of Medicines',
                ru: 'Импорт ЛС',
                az: 'DV idxalı',
                documents:{
                    en: 'Documents',
                    ru: 'Документы',
                    az: 'Sənədlər',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=35',
                        ru: '/index.php?lang=2&ind=main&id=35',
                        az: '/index.php?lang=1&ind=main&id=35',
                    }
                },
                letter_samples:{
                    en: 'Letter Samples',
                    ru: 'Образцы писем',
                    az: 'Məktub nümunələri',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=34',
                        ru: '/index.php?lang=3&ind=main&id=34',
                        az: '/index.php?lang=3&ind=main&id=34',
                    }
                }
            },
            control_on_medicines:{
                hasSubMenu: true,
                en: 'Control on Medicines',
                ru: 'Контроль над ЛС',
                az: 'DV nəzarəti',
                control_on_medicines:{
                    en: 'Control on Medicines',
                    ru: 'Контроль над ЛС',
                    az: 'Dərman nəzarəti haqqında',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=28',
                        ru: '/ind:ex.php?lang=2&ind=main&id=28',
                        az: '/index.php?lang=1&ind=main&id=28',
                    }
                }
            },
            registration_of_medicines:{
                hasSubMenu: true,
                en: 'Registration of Medicines',
                ru: 'Регистрация ЛС',
                az: 'DV qeydiyyatı',
                department_of_registration:{
                    en: 'Department of Registration',
                    ru: 'Отдел Регистрации',
                    az: 'Qeydiyyat Şöbəsi',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=36',
                        ru: '/index.php?lang=2&ind=main&id=36',
                        az: '/index.php?lang=1&ind=main&id=36',
                    }
                },
                guidelines_and_annexes:{
                    en: 'Legislation',
                    ru: 'Законодательство',
                    az: 'Qanunvericilik',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=71',
                        ru: '/index.php?lang=2&ind=main&id=71',
                        az: '/index.php?lang=1&ind=main&id=71',
                    }
                },
                expertise_of_FS:{
                    en: 'Expertise of FS',
                    ru: 'Экспертиза БАД',
                    az: 'BMFQƏ-nin Ekspertizası',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=158',
                        ru: '/index.php?lang=2&ind=main&id=158',
                        az: '/index.php?lang=1&ind=main&id=158',
                    }
                },
                result_of_the_expertise_of_FS:{
                    en: 'Result of the Expertise of FS',
                    ru: 'Реультаты экспертизы БАД',
                    az: 'BFMQƏ-in ekspertiza nəticələri',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=179',
                        ru: '/index.php?lang=2&ind=main&id=179',
                        az: '/index.php?lang=1&ind=main&id=179',
                    }
                },
                reexamination:{
                    en: 'Reexpertise of FS',
                    ru: 'Повторная экспертиза БАД',
                    az: 'BFMQƏ – təkrar ekspertiza',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=190',
                        ru: '/index.php?lang=2&ind=main&id=190',
                        az: '/index.php?lang=1&ind=main&id=190',
                    }
                }
            },
            laboratory:{
                hasSubMenu: true,
                en: 'Laboratory',
                ru: 'Лаборатория ',
                az: 'Laboratoriya',
                about_the_quality_control_laboratory:{
                    en: 'About the Quality Control Laboratory',
                    ru: 'О Лаборатории  Контроля  Качества',
                    az: 'DVKYL haqqında',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=48',
                        ru: '/index.php?lang=2&ind=main&id=48',
                        az: '/index.php?lang=1&ind=main&id=48',
                    }
                }
            },
            pharmacovigilance:{
                hasSubMenu: true,
                en: 'Pharmacovigilance',
                ru: 'Фармаконадзор',
                az: 'Farmakonəzarət',
                general_information:{
                    en: 'General information',
                    ru: 'Общие сведения',
                    az: 'Ümumi məlumatlar',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=62',
                        ru: '/index.php?lang=2&ind=main&id=62',
                        az: '/index.php?lang=1&ind=main&id=62',
                    }
                },
                legislative_acts:{
                    en: 'Legislative acts',
                    ru: 'Законодательные акты',
                    az: 'Qanunvericilik',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=23',
                        ru: '/index.php?lang=2&ind=main&id=23',
                        az: '/index.php?lang=1&ind=main&id=23',
                    }
                },
                information_for_healthcare_professionals:{
                    en: 'Information for healthcare professionals',
                    ru: 'Информация для медицинских работников',
                    az: 'Tibb işçiləri üçün məlumatlar',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=73',
                        ru: '/index.php?lang=2&ind=main&id=73',
                        az: '/index.php?lang=1&ind=main&id=73',
                    }
                },
                information_for_patients:{
                    en: 'Information for Patients',
                    ru: 'Информация для пациентов',
                    az: 'Pasiyentlər üçün məlumatlar',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=74',
                        ru: '/index.php?lang=2&ind=main&id=74',
                        az: '/index.php?lang=1&ind=main&id=74',
                    }
                },
                pharmacovigilance_dictionary:{
                    en: 'Pharmacovigilance dictionary',
                    ru: 'Словарь по фармаконадзору',
                    az: 'Farmakonəzarət lüğəti',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=75',
                        ru: '/index.php?lang=2&ind=main&id=75',
                        az: '/index.php?lang=1&ind=main&id=75',
                    }
                },
                reporting_forms:{
                    en: 'Reporting forms',
                    ru: 'Карты сообщения',
                    az: 'Bildiriş kartları',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=79',
                        ru: '/index.php?lang=2&ind=main&id=79',
                        az: '/index.php?lang=1&ind=main&id=79',
                    }
                },
                online_reporting:{
                    en: 'Online reporting',
                    ru: 'Онлайн сообщения',
                    az: 'Onlayn bildirişlər',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=80',
                        ru: '/index.php?lang=2&ind=main&id=80',
                        az: '/index.php?lang=1&ind=main&id=80',
                    }
                },
                contacts:{
                    en: 'Contacts',
                    ru: 'Контакты',
                    az: 'Əlaqə',
                    url:{
                        en: '/index.php?lang=3&ind=main&id=78',
                        ru: '/index.php?lang=2&ind=main&id=78',
                        az: '/index.php?lang=1&ind=main&id=78',
                    }
                }
            },
            private_medical_institutions:{
                hasSubMenu: true,
                en: 'Private medical institutions',
                ru: 'Частные мед. учреждения',
                az: 'Özəl tibb müəssisələri',
                SK:{
                    en:'Sanatorium Resort',
                    ru:'Санаторий Резорт',
                    az:'Sanator-Kurort',
                    url:{
                        en: '/SK',
                        ru: '/SK',
                        az: '/SK',
                    }
                },
                AMB:{
                    en:'Ambulatory',
                    ru:'Амбулаторный',
                    az:'Ambulator',
                    url:{
                        en: '/AMB',
                        ru: '/AMB',
                        az: '/AMB',
                    }
                },
                STAS:{
                    en:'Stationary',
                    ru:'Стационарный',
                    az:'Stasionar',
                    url:{
                        en: '/STAS',
                        ru: '/STAS',
                        az: '/STAS',
                    }
                },
                STOM:{
                    en:'Dentists',
                    ru:'Стоматология',
                    az:'Stomatoloji',
                    url:{
                        en: '/STOM',
                        ru: '/STOM',
                        az: '/STOM',
                    }
                },
            },
            rates:{
                hasSubMenu: false,
                en: 'Rates',
                ru: 'Тарифы',
                az: 'Tariflər',
                url:{
                    en: '/index.php?lang=3&ind=main&id=83',
                    ru: '/index.php?lang=2&ind=main&id=83',
                    az: '/index.php?lang=1&ind=main&id=83'
                }
            },
            contacts:{
                hasSubMenu: false,
                en: 'Contacts',
                ru: 'Контакты',
                az: 'Əlaqə',
                url:{
                    en: '/index.php?lang=3&ind=fb',
                    ru: '/index.php?lang=2&ind=fb',
                    az: '/index.php?lang=1&ind=fb',
                }
            }
        },
        searchPages:{
            find:{
                en: 'Find',
                ru: 'Поиск',
                az: 'Axtar'
            },
            searching_results:{
                en: 'Searching results',
                ru: 'Результаты поиска',
                az: 'Axtarış nəticələri'
            },
            empty_search_result:{
                en: 'Nothing was found',
                ru: 'Ничего не найдено',
                az: 'Heçnə tapılmayıb'
            },
            total:{
                en: 'Total',
                ru: 'Всего',
                az: 'Ümumi'
            },
            page:{
                en: 'page',
                ru: 'страница',
                az: 'səhifə'
            },
            pages:{
                en: 'pages',
                ru: 'страниц',
                az: 'səhifə'
            },
            showing:{
                en: 'Showing',
                ru: 'Показывается',
                az: 'Göstərilir'
            },
            medicines: {
                search_for:{
                    en: 'Search for medicines',
                    ru: 'Поиск лекарств',
                    az: 'Dərman vasitələrinin axtarışı'
                }
            },
            pharma_companies: {
                search_for:{
                    en: 'Search for Pharma Companies',
                    ru: 'Поиск аптеки',
                    az: 'Aptekin axtarışı'
                }
            },
            medical_institutions: {
                search_for:{
                    en: 'Search for medical institutions',
                    ru: 'Поиск медицинских учреждений',
                    az: 'Tibb müəssisələrini axtarın'
                }
            }
        },
        multiselect:{
            nSelectedText:{
                en:'seleted',
                ru:'выбрано',
                az:'seçilib'
            },
            nonSelectedText:{
                en:'All',
                ru:'Все',
                az:'Hamısı'
            }
        }
    }
}

function mainReducer (state = MAIN_INITIAL_STATE, action) {
    switch (action.type) {
        case SET_FACILITY_TYPE:
            let newState = JSON.parse(JSON.stringify(state));
            newState.medical_institutions.find_form.FacilityType.options[action.payload].selected = !newState.medical_institutions.find_form.FacilityType.options[action.payload].selected;
            return newState;
        case CHANGE_ACTIVE_TAB:
            switch (action.payload) {
                case 'medicines':
                    return {
                        ...state,
                        mainPage:{
                            ...state.mainPage,
                            searchTabs:{
                                ...state.mainPage.searchTabs,
                                medicines:{
                                    ...state.mainPage.searchTabs.medicines,
                                    active: true
                                },
                                pharma_companies:{
                                    ...state.mainPage.searchTabs.pharma_companies,
                                    active: false
                                },
                                medical_institutions:{
                                    ...state.mainPage.searchTabs.medical_institutions,
                                    active: false
                                }
                            }
                        }
                    };
                case 'pharma_companies':
                    return {
                        ...state,
                        mainPage:{
                            ...state.mainPage,
                            searchTabs:{
                                ...state.mainPage.searchTabs,
                                medicines:{
                                    ...state.mainPage.searchTabs.medicines,
                                    active: false
                                },
                                pharma_companies:{
                                    ...state.mainPage.searchTabs.pharma_companies,
                                    active: true
                                },
                                medical_institutions:{
                                    ...state.mainPage.searchTabs.medical_institutions,
                                    active: false
                                }
                            }
                        }
                    };
                case 'medical_institutions':
                    return {
                        ...state,
                        mainPage:{
                            ...state.mainPage,
                            searchTabs:{
                                ...state.mainPage.searchTabs,
                                medicines:{
                                    ...state.mainPage.searchTabs.medicines,
                                    active: false
                                },
                                pharma_companies:{
                                    ...state.mainPage.searchTabs.pharma_companies,
                                    active: false
                                },
                                medical_institutions:{
                                    ...state.mainPage.searchTabs.medical_institutions,
                                    active: true
                                }
                            }
                        }
                    }
            }
        default:
            return state;
    }
}

const LANGUAGE_INITIAL_STATE = localStorage.getItem("language") || 'az';

function languageReducer(state = LANGUAGE_INITIAL_STATE, action) {
    switch (action.type) {
        case SET_LANGUAGE:
            localStorage.setItem("language", action.payload);
            return action.payload;
        default: return state;
    }
}

const SEARCH_INITIAL_STATE = {
    breadcrumbs:{
        medicines:{
            en:'Search for medicines',
            ru:'Поиск лекарств',
            az:'Dərman vasitələrinin axtarışı'
        },
        medicine_details:{
            en:'Search for medicines',
            ru:'Поиск лекарств',
            az:'Dərman vasitələrinin axtarışı'
        },
        medical_institutions:{
            en:'Search for medical institutions',
            ru:'Поиск медицинских учреждений',
            az:'Tibb müəssisələrini axtarın'
        },
        medical_institution_details:{
            en:'Search for medical institutions',
            ru:'Поиск медицинских учреждений',
            az:'Tibb müəssisələrini axtarın'
        },
        SK:{
            en:'Search for medical institutions',
            ru:'Поиск медицинских учреждений',
            az:'Tibb müəssisələrini axtarın'
        },
        AMB:{
            en:'Search for medical institutions',
            ru:'Поиск медицинских учреждений',
            az:'Tibb müəssisələrini axtarın'
        },
        STAS:{
            en:'Search for medical institutions',
            ru:'Поиск медицинских учреждений',
            az:'Tibb müəssisələrini axtarın'
        },
        STOM:{
            en:'Search for medical institutions',
            ru:'Поиск медицинских учреждений',
            az:'Tibb müəssisələrini axtarın'
        },
        pharma_companies:{
            en:'Search for Pharma Companies',
            ru:'Поиск аптеки',
            az:'Aptekin axtarışı'
        },
        home_url:{
            en: '/',
            ru: '/',
            az: '/',
        }
    },
    loaderStatus: false,
    detailsLoaderStatus: true,
    all_finds: [],
    all_finds_count: 0,
    pages_count: 1,
    autocomplites: [],
    filters:{
        Name: '',
        Address: '',
        ActualAddress: '',
        Kind: '',
        Composition: '',
        Manufacturer: '',
        CertificateNumber: '',
        Registration: '',
        FacilityType: '',
        ITN: '',

        active_page: 1
    },
    details: {},
    who: ''
};

function searchReducer(state = SEARCH_INITIAL_STATE, action) {
    switch (action.type) {
        case SET_ACTIVE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    active_page: action.payload
                }
            };
        case SET_LOADER_STATUS:
            return {
                ...state,
                loaderStatus: action.payload
            };
        case SET_KIND:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    Kind: action.payload
                }
            };
        case SET_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload
                }
            };
        case SET_MEDICAL_INSTITUTIONS:
        case SET_MEDICINES:
        case SET_PHARMA_COMPANIES:
            return {
                ...state,
                all_finds: action.payload,
                loaderStatus: false
            };
        case SET_MEDICAL_INSTITUTIONS_COUNT:
        case SET_MEDICINES_COUNT:
        case SET_PHARMA_COMPANIES_COUNT:
            return {
                ...state,
                all_finds_count: action.payload,
                pages_count: Math.ceil(action.payload / 10),
                loaderStatus: false
            };
        case SET_MEDICINE_DETAILS:
            return {
                ...state,
                details: action.payload,
                who: 'medicine_details',
                detailsLoaderStatus: false
            };
        case SET_MEDICAL_INSTITUTION_DETAILS:
            return {
                ...state,
                details: action.payload,
                who: 'medical_institution_details',
                detailsLoaderStatus: false
            };
        case SET_MEDICAL_INSTITUTIONS_FOR_AUTOCOMPLETE:
        case SET_MEDICINES_FOR_AUTOCOMPLETE:
        case SET_PHARMA_COMPANIES_FOR_AUTOCOMPLETE:
            return {
                ...state,
                autocomplites: action.payload
            };

        default: return state;
    }
}


export default combineReducers({mainReducer, languageReducer, searchReducer});
