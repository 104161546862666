import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';

export const SET_MEDICAL_INSTITUTIONS = 'SET_MEDICAL_INSTITUTIONS';
export const SET_MEDICAL_INSTITUTIONS_COUNT = 'SET_MEDICAL_INSTITUTIONS_COUNT';
export const SET_MEDICAL_INSTITUTION_DETAILS = 'SET_MEDICAL_INSTITUTION_DETAILS';
export const SET_MEDICAL_INSTITUTIONS_FOR_AUTOCOMPLETE = 'SET_MEDICAL_INSTITUTIONS_FOR_AUTOCOMPLETE';

function setMedicalInstitutions(items) {
    return {
        type: SET_MEDICAL_INSTITUTIONS,
        payload: items
    }
}

export function getMedicalInstitutions(active_page, filter) {
    return function (dispatch) {
        (async () => {
            let response = await fetch('/api/health-facilities/extened-find?_limit=10&_sort=Name&_start=' + (active_page - 1) * 10 +
                (filter.Name && (`&Name=%25${filter.Name.replace(/%/g,'\\%25')}%25`)) +
                (filter.ActualAddress && (`&ActualAddress=%25${filter.ActualAddress.replace(/%/g,'\\%25')}%25`)) +
                (filter.Kind && (`&Kind=${filter.Kind}`)) +
                (filter.FacilityType && (`&FacilityType=${filter.FacilityType}`)));

            if (response.ok) {
                dispatch(setMedicalInstitutions(await response.json()));
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        })();
    }
}

function setMedicalInstitutionsCount(items_count) {
    return {
        type: SET_MEDICAL_INSTITUTIONS_COUNT,
        payload: items_count
    }
}

export function getMedicalInstitutionsCount(filter) {
    return function (dispatch) {
        (async () => {
            let response = await fetch('/api/health-facilities/extened-count?' +
                (filter.Name && (`&Name=%25${filter.Name.replace(/%/g,'\\%25')}%25`)) +
                (filter.ActualAddress && (`&ActualAddress=%25${filter.ActualAddress.replace(/%/g, '\\%25')}%25`)) +
                (filter.Kind && (`&Kind=${filter.Kind}`)) +
                (filter.FacilityType && (`&FacilityType=${filter.FacilityType}`)));

            if (response.ok) {
                dispatch(setMedicalInstitutionsCount(await response.json()));
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        })();
    }
}

function setMedicalInstitutionDetails(item) {
    return {
        type: SET_MEDICAL_INSTITUTION_DETAILS,
        payload: item
    }
}

export function getMedicalInstitutionDetails(id) {
    return function (dispatch) {
        (async () => {
            let response = await fetch(`/api/health-facilities/${id}`);

            if (response) {
                dispatch(setMedicalInstitutionDetails(await response.json()));
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        })();
    }
}

function setMedicalInstitutionsForAutocomplete(items) {
    return {
        type: SET_MEDICAL_INSTITUTIONS_FOR_AUTOCOMPLETE,
        payload: items
    }
}

export function getMedicalInstitutionsForAutocomplete(sort_by, filter) {
    return function (dispatch) {
        (async () => {
            let response = await fetch('/api/health-facilities/extened-find?_limit=10' +
                (sort_by && ('&OrderBy=' + sort_by)) +
                (sort_by && ('&Distinct=' + sort_by)) +
                (filter.Name && (`&Name=%25${filter.Name.replace(/%/g, '\\%25')}%25`)) +
                (filter.ActualAddress && (`&ActualAddress=%25${filter.ActualAddress.replace(/%/g, '\\%25')}%25`)) +
                (filter.Kind && (`&Kind=${filter.Kind}`)) +
                (filter.FacilityType && (`&FacilityType=${filter.FacilityType}`)));

            if (response.ok) {
                dispatch(setMedicalInstitutionsForAutocomplete(await response.json()));
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        })();
    }
}