import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';

export const SET_MEDICINES = 'SET_MEDICINES';
export const SET_MEDICINES_COUNT = 'SET_MEDICINES_COUNT';
export const SET_MEDICINE_DETAILS = 'SET_MEDICINE_DETAILS';
export const SET_MEDICINES_FOR_AUTOCOMPLETE = 'SET_MEDICINES_FOR_AUTOCOMPLETE';

function setMedicines(items) {
    return {
        type: SET_MEDICINES,
        payload: items
    }
}

export function getMedicines(active_page, filter) {
    return function (dispatch) {
        (async () => {
            let response = await fetch('/api/drugs/extened-find?_limit=10&_sort=Name&_start=' + (active_page - 1) * 10 +
                (filter.CertificateNumber && (`&CertificateNumber=%25${filter.CertificateNumber.replace(/%/g,'\\%25')}%25`)) +
                (filter.Name && (`&Name=%25${filter.Name.replace(/%/g,'\\%25')}%25`)) +
                (filter.Composition && (`&Composition=%25${filter.Composition.replace(/%/g,'\\%25')}%25`)) +
                (filter.Manufacturer && (`&Manufacturer=%25${filter.Manufacturer.replace(/%/g,'\\%25')}%25`)));

            if (response.ok) {
                dispatch(setMedicines(await response.json()));
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        })();
    }
}

function setMedicinesCount(items_count) {
    return {
        type: SET_MEDICINES_COUNT,
        payload: items_count
    }
}

export function getMedicinesCount(filter) {
    return function (dispatch) {
        (async () => {
            let response = await fetch('/api/drugs/extened-count?' +
                (filter.CertificateNumber && (`&CertificateNumber=%25${filter.CertificateNumber.replace(/%/g,'\\%25')}%25`)) +
                (filter.Name && (`&Name=%25${filter.Name.replace(/%/g,'\\%25')}%25`)) +
                (filter.Composition && (`&Composition=%25${filter.Composition.replace(/%/g,'\\%25')}%25`)) +
                (filter.Manufacturer && (`&Manufacturer=%25${filter.Manufacturer.replace(/%/g,'\\%25')}%25`)));

            if (response.ok) {
                dispatch(setMedicinesCount(await response.json()));
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        })();
    }
}

function setMedicineDetails(item) {
    return {
        type: SET_MEDICINE_DETAILS,
        payload: item
    }
}

export function getMedicineDetails(id) {
    return function (dispatch) {
        (async () => {
            let response = await fetch(`/api/drugs/uuid?Uuid=${id}`);

            if (response) {
                dispatch(setMedicineDetails(await response.json()));
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        })();
    }
}

function setMedicinesForAutocomplete(items) {
    return {
        type: SET_MEDICINES_FOR_AUTOCOMPLETE,
        payload: items
    }
}

export function getMedicinesForAutocomplete(sort_by, filter) {
    return function (dispatch) {
        (async () => {
            let response = await fetch('/api/drugs/extened-find?_limit=10' +
                (sort_by && ('&OrderBy=' + sort_by)) +
                (sort_by && ('&Distinct=' + sort_by)) +
                (filter.CertificateNumber && (`&CertificateNumber=%25${filter.CertificateNumber.replace(/%/g,'\\%25')}%25`)) +
                (filter.Name && (`&Name=%25${filter.Name.replace(/%/g,'\\%25')}%25`)) +
                (filter.Composition && (`&Composition=%25${filter.Composition.replace(/%/g,'\\%25')}%25`)) +
                (filter.Manufacturer && (`&Manufacturer=%25${filter.Manufacturer.replace(/%/g,'\\%25')}%25`)));

            if (response.ok) {
                dispatch(setMedicinesForAutocomplete((await response.json())));
            } else {
                alert("Ошибка HTTP: " + response.status);
            }
        })();
    }
}