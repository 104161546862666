import React, {Component} from 'react';
import {connect} from "react-redux";
import './find_form.css';
import {
    setActive,
    setFacilityType,
    setFilter,
    setLoaderStatus
} from "../../Actions/simpleActions";
import {
    getMedicalInstitutions,
    getMedicalInstitutionsCount,
    getMedicalInstitutionsForAutocomplete
} from "../../Actions/medicalInstitutionsActions";
import {
    getMedicines,
    getMedicinesCount,
    getMedicinesForAutocomplete
} from "../../Actions/medicinesActions";
import {
    getPharmaCompanies,
    getPharmaCompaniesCount,
    getPharmaCompaniesForAutocomplete
} from "../../Actions/pharmaCompaniesActions";
import {Typeahead} from 'react-bootstrap-typeahead';
import MultiSelect from 'react-bootstrap-multiselect';
import $ from 'jquery';

class FindForm extends Component {
    componentDidMount() {
        if($('.multiselect-container.dropdown-menu')[0]) $('.multiselect-container.dropdown-menu').first().remove();
    }

    constructor(props) {
        super(props);
        this.state = {
            Name: this.props.search.filters.Name,
            Address: this.props.search.filters.Address,
            ActualAddress: this.props.search.filters.ActualAddress,
            Kind: this.props.search.filters.Kind,
            Composition: this.props.search.filters.Composition,
            Manufacturer: this.props.search.filters.Manufacturer,
            CertificateNumber: this.props.search.filters.CertificateNumber,
            Registration: this.props.search.filters.Registration,
            FacilityType: this.props.search.filters.FacilityType,
            ITN: this.props.search.filters.ITN,
            active_page: this.props.search.filters.active_page
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(item, value, param) {
        const newState = {
            ...this.state,
            [item]: value
        };

        if(param){
            switch (this.props.who) {
                case 'medical_institutions':
                    this.props.getMedicalInstitutionsForAutocomplete(item, newState);
                    break;
                case 'medicines':
                    this.props.getMedicinesForAutocomplete(item, newState);
                    break;
                case 'pharma_companies':
                    this.props.getPharmaCompaniesForAutocomplete(item, newState);
                    break;
            }
        }

        this.setState(newState);

    }

    handleSubmit(event) {
        switch (this.props.who) {
            case 'medical_institutions':
                this.props.getMedicalInstitutions(1, this.state);
                break;
            case 'medicines':
                this.props.getMedicines(1, this.state);
                break;
            case 'pharma_companies':
                this.props.getPharmaCompanies(1, this.state);
                break;
        }
        event.preventDefault();
    }

    render() {
        let fields = [];
        let types = [];
        let all_fields = this.props.forms[this.props.who].find_form;

        for(let item in all_fields){

            if(all_fields[item].type === 'dropdown'){
                let options = all_fields[item].options;
                let options_arr = [];

                for(let option in options){
                    if(this.state.Kind === option){
                        options_arr.push(
                            <option selected value={option}>{options[option][this.props.language]}</option>
                        );
                    } else if (option === 'all'){
                        options_arr.push(
                            <option value="">{options[option][this.props.language]}</option>
                        );
                    } else {
                        options_arr.push(
                            <option value={option}>{options[option][this.props.language]}</option>
                        );
                    }

                }

                fields.push(
                    <div className="form-group col-lg-6">
                        <label className="col-lg-4 control-label">{all_fields[item][this.props.language]}</label>
                        <div className="col-lg-8">
                            <select className="form-control" data-target="Kind" onChange={(event) => this.handleChange(item, event.target.value)}>
                                {options_arr}
                            </select>
                        </div>
                    </div>
                )
            } else if (all_fields[item].type === 'dropdown_multiselect') {
                let options = all_fields[item].options;
                let options_arr = [];

                for(let option in options) {
                    options_arr.push({
                        label: options[option][this.props.language],
                        value: options[option].value,
                        selected: options[option].selected
                    })
                }

                fields.push(
                    <div className="form-group col-lg-6">
                        <label className="col-lg-4 control-label">{all_fields[item][this.props.language]}</label>
                        <div className="col-lg-8">
                            <MultiSelect
                                multiple
                                data={options_arr}
                                nSelectedText={this.props.multiselect.nSelectedText[this.props.language]}
                                nonSelectedText={this.props.multiselect.nonSelectedText[this.props.language]}
                                onChange={
                                    (option) => {
                                        let value = option[0].value;
                                        if(value){
                                            let index = types.indexOf(value);

                                            if(index === -1) types.push(value);
                                            else types.splice(index, 1);
                                            this.props.setFacilityType(value);
                                            this.handleChange(item, types.join(','));
                                        }
                                    }
                                }
                            />
                        </div>
                    </div>
                )

            } else {
                fields.push(
                    <div className="form-group col-lg-6">
                        <label className="col-lg-4 control-label">{all_fields[item][this.props.language]}</label>
                        <div className="col-lg-8">
                            <Typeahead
                                defaultInputValue={item === 'CertificateNumber' ? this.state[item].replace(/№| |DV|N/g, '') : this.state[item]}
                                className="not_show"
                                multiple={false}
                                labelKey={
                                    (value) => {
                                        if(item === 'CertificateNumber'){
                                            if(value.hasOwnProperty(item)) return value[item].replace(/№| |DV|N/g, '');
                                            else return '';
                                        }
                                        else{
                                            if(value.hasOwnProperty(item)) return value[item];
                                            else return '';
                                        }
                                    }
                                }
                                onFocus={(value) => this.handleChange(item, value.target.value, true)}
                                onChange={
                                    (value) => {
                                        if (value[0]) {
                                            this.handleChange(item, value[0][item]);
                                        }
                                    }
                                }
                                onInputChange={(value) => this.handleChange(item, value, true)}
                                onKeyDown={
                                    (event) => {
                                        if (event.keyCode === 13){
                                            setTimeout(() => {
                                                this.handleSubmit(event);
                                            })

                                        }
                                    }
                                }
                                options={this.props.search.autocomplites}
                            />
                        </div>
                    </div>
                );
            }
        }

        let field_groups = [];

        for(let i = 0; i < fields.length; i += 2){
            if(fields[i + 1]){
                field_groups.push(
                    <div className="row" key={i}>
                        {fields[i]}
                        {fields[i + 1]}
                    </div>
                );
            } else {
                field_groups.push(
                    <div className="row" key={i}>
                        {fields[i]}
                    </div>
                );
            }
        }

        return (
            <div className="panel panel-default">
                <div className="panel-body">
                    <form className="form-horizontal" onSubmit={this.handleSubmit}>
                        {field_groups}
                        <div className="form-group">
                            <div className="col-lg-10 col-lg-offset-2">
                                <button type="submit" className="btn btn-primary">{this.props.forms.dictionary.searchPages.find[this.props.language]}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    forms: state.mainReducer,
    multiselect: state.mainReducer.dictionary.multiselect,
    language: state.languageReducer,
    search: state.searchReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        setFacilityType: (facility_type) => {
            dispatch(setFacilityType(facility_type));
        },
        getMedicalInstitutions: (active_page, filter) => {
            if(
                filter.Name.trim().length !== 0 ||
                filter.ActualAddress.trim().length !== 0 ||
                filter.FacilityType.trim().length !== 0
            ){
                dispatch(setActive(active_page));
                dispatch(setFilter(filter));
                dispatch(getMedicalInstitutions(active_page, filter));
                dispatch(getMedicalInstitutionsCount(filter));
            }
        },
        getMedicalInstitutionsForAutocomplete: (sort_by, filter) => {
            if(
                filter.Name.trim().length !== 0 ||
                filter.ActualAddress.trim().length !== 0 ||
                filter.FacilityType.trim().length !== 0
            ) {
                dispatch(setFilter(filter));
                dispatch(getMedicalInstitutionsForAutocomplete(sort_by, filter))
            }
        },
        getMedicines: (active_page, filter) => {
            if(
                filter.CertificateNumber.trim().length !== 0 ||
                filter.Name.trim().length !== 0 ||
                filter.Composition.trim().length !== 0 ||
                filter.Manufacturer.trim().length !== 0
            ) {
                dispatch(setLoaderStatus(true));
                dispatch(setActive(active_page));
                dispatch(setFilter(filter));
                dispatch(getMedicines(active_page, filter));
                dispatch(getMedicinesCount(filter));
            }
        },
        getMedicinesForAutocomplete: (sort_by, filter) => {
            if(
                filter.CertificateNumber.trim().length !== 0 ||
                filter.Name.trim().length !== 0 ||
                filter.Composition.trim().length !== 0 ||
                filter.Manufacturer.trim().length !== 0
            ) {
                dispatch(setFilter(filter));
                dispatch(getMedicinesForAutocomplete(sort_by, filter))
            }
        },
        getPharmaCompanies: (active_page, filter) => {
            if(
                filter.Name.trim().length !== 0 ||
                filter.Address.trim().length !== 0 ||
                filter.Registration.trim().length !== 0 ||
                filter.ITN.trim().length !== 0
            ) {
                dispatch(setLoaderStatus(true));
                dispatch(setActive(active_page));
                dispatch(setFilter(filter));
                dispatch(getPharmaCompanies(active_page, filter));
                dispatch(getPharmaCompaniesCount(filter));
            }
        },
        getPharmaCompaniesForAutocomplete: (sort_by, filter) => {
            if(
                filter.Name.trim().length !== 0 ||
                filter.Address.trim().length !== 0 ||
                filter.Registration.trim().length !== 0 ||
                filter.ITN.trim().length !== 0
            ) {
                dispatch(setFilter(filter));
                dispatch(getPharmaCompaniesForAutocomplete(sort_by, filter));
            }
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FindForm);
