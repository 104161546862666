import React, {Component} from 'react';
import './pagination.css';
import {connect} from "react-redux";
import {setActive, setLoaderStatus} from "../../Actions/simpleActions";
import {getMedicalInstitutions} from "../../Actions/medicalInstitutionsActions";
import {getMedicines} from "../../Actions/medicinesActions";
import {getPharmaCompanies} from "../../Actions/pharmaCompaniesActions";

class MyPagination extends Component {

    render() {
        let filter = {
            Name: this.props.pagination.filters.Name,
            Address: this.props.pagination.filters.Address,
            ActualAddress: this.props.pagination.filters.ActualAddress,
            License: this.props.pagination.filters.License,
            Kind: this.props.pagination.filters.Kind,
            Composition: this.props.pagination.filters.Composition,
            Manufacturer: this.props.pagination.filters.Manufacturer,
            CertificateNumber: this.props.pagination.filters.CertificateNumber,
            Registration: this.props.pagination.filters.Registration,
            FacilityType: this.props.pagination.filters.FacilityType,
            ITN: this.props.pagination.filters.ITN,
            active_page: this.props.pagination.filters.active_page
        };

        return (
            <div>
                <div className="col-sm-6 text-left">
                    <ul className="pagination">
                        {this.props.pagination.filters.active_page > 3 ? <li onClick={() => this.props.setActive(1, filter, this.props.who)}><a>|&lt;</a></li> : null}
                        {this.props.pagination.filters.active_page > 3 ? <li onClick={() => this.props.setActive(this.props.pagination.filters.active_page - 1, filter, this.props.who)}><a>&lt;</a></li> : null}

                        {this.props.pagination.filters.active_page === 3 ? <li onClick={() => this.props.setActive(1, filter, this.props.who)}><a>1</a></li> : null}
                        {this.props.pagination.filters.active_page > 1 ? <li onClick={() => this.props.setActive(this.props.pagination.filters.active_page - 1, filter, this.props.who)}><a>{this.props.pagination.filters.active_page - 1}</a></li> : null}
                        <li className="active"><a>{this.props.pagination.filters.active_page}</a></li>
                        {this.props.pagination.filters.active_page < this.props.pagination.pages_count ? <li onClick={() => this.props.setActive(this.props.pagination.filters.active_page + 1, filter, this.props.who)}><a>{this.props.pagination.filters.active_page + 1}</a></li> : null}
                        {this.props.pagination.filters.active_page === this.props.pagination.pages_count - 2 ? <li onClick={() => this.props.setActive(this.props.pagination.filters.active_page + 2, filter, this.props.who)}><a>{this.props.pagination.filters.active_page + 2}</a></li> : null}

                        {this.props.pagination.filters.active_page < this.props.pagination.pages_count  - 2 ? <li onClick={() => this.props.setActive(this.props.pagination.filters.active_page + 1, filter, this.props.who)}><a>&gt;</a></li> : null}
                        {this.props.pagination.filters.active_page < this.props.pagination.pages_count  - 2 ? <li onClick={() => this.props.setActive(this.props.pagination.pages_count, filter, this.props.who)}><a>&gt;|</a></li> : null}
                    </ul>
                </div>
                <div className="col-sm-6 text-right">
                    <div className="results">{this.props.search_page.showing[this.props.language]}: {((this.props.pagination.filters.active_page - 1) * 10 + 1) > this.props.pagination.all_finds_count ? this.props.pagination.all_finds_count : ((this.props.pagination.filters.active_page - 1) * 10 + 1)} - {(this.props.pagination.filters.active_page * 10) > this.props.pagination.all_finds_count ? this.props.pagination.all_finds_count : (this.props.pagination.filters.active_page * 10)}. {this.props.search_page.total[this.props.language]}: {this.props.pagination.all_finds_count} ({this.props.pagination.pages_count} {this.props.pagination.pages_count === 1 ? this.props.search_page.page[this.props.language] : this.props.search_page.pages[this.props.language]})</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    language: state.languageReducer,
    pagination: state.searchReducer,
    search_page: state.mainReducer.dictionary.searchPages
});

const mapDispatchToProps = (dispatch) => {
    return {
        setActive: (number, filter, who) => {
            dispatch(setActive(number));
            switch (who) {
                case 'medical_institutions':
                    dispatch(setLoaderStatus(false));
                    dispatch(getMedicalInstitutions(number, filter));
                    break;
                case 'medicines':
                    dispatch(setLoaderStatus(false));
                    dispatch(getMedicines(number, filter));
                    break;
                case 'pharma_companies':
                    dispatch(setLoaderStatus(false));
                    dispatch(getPharmaCompanies(number, filter));
                    break;
            }
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPagination);