import React from 'react';
import './App.css';
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import {BrowserRouter, Link, Switch, Route} from "react-router-dom";
import SearchPage from "./components/searchPage/searchPage";
import Details from "./components/searchPage/details";
import HomePage from "./components/homePage/homePage";
import Favicon from 'react-favicon';

function App() {
    return (
        <div>
            <Favicon url='/assets/imgs/icon.ico'/>
            <Header />
            <BrowserRouter>
                <Switch>
                    <Route exact path={'/medical_institutions'} render={() => <SearchPage who="medical_institutions" Kind=""/>}/>
                    <Route exact path={'/SK'} render={() => <SearchPage who="medical_institutions" Kind="SK"/>}/>
                    <Route exact path={'/AMB'} render={() => <SearchPage who="medical_institutions" Kind="AMB"/>}/>
                    <Route exact path={'/STAS'} render={() => <SearchPage who="medical_institutions" Kind="STAS"/>}/>
                    <Route exact path={'/STOM'} render={() => <SearchPage who="medical_institutions" Kind="STOM"/>}/>
                    <Route exact path={'/medical_institution_details/:id'} component={Details}/>
                    <Route exact path={'/medicines'} render={() => <SearchPage who="medicines"/>}/>
                    <Route exact path={'/medicine_details/:id'} component={Details}/>
                    <Route exact path={'/pharma_companies'} render={() => <SearchPage who="pharma_companies"/>}/>
                    <Route exact path={'/'} component={HomePage}/>
                </Switch>
            </BrowserRouter>
            {/*<Footer />*/}
        </div>
        )
    ;
}

export default App;
