import React, {Component} from 'react';
import {connect} from "react-redux";
import Table from "react-bootstrap/Table";
import ResultRows from "./result_rows";
import {setLoaderStatus} from "../../Actions/simpleActions";
import Breadcrumbs from "../breadcrumbs/breadcrumbs";
import Loader from "react-loader-spinner";
import {getMedicineDetails} from "../../Actions/medicinesActions";
import {getMedicalInstitutionDetails} from "../../Actions/medicalInstitutionsActions";

class Details extends Component {
    componentDidMount() {
        switch (window.location.pathname.split('/')[1]) {
            case "medicine_details":
                this.props.getMedicineDetails(this.props.match.params.id);
                break;
            case "medical_institution_details":
                this.props.getMedicalInstitutionDetails(this.props.match.params.id);
                break;
        }
    }

    render() {
        return (
            <div className="container" style={{marginTop: '45px'}}>
                <div className="row">
                    <div className="col-sm-12">
                        <Breadcrumbs name={this.props.details.Name} who={window.location.pathname.split('/')[1]}/>
                        <div className="col-lg-12">
                            {this.props.loaderStatus ?
                                <Loader
                                    type="Oval"
                                    color="gray"
                                    height={100}
                                    width={100}
                                /> :
                                <div>
                                    <h3>{this.props.details.Name}</h3>
                                    <br/>
                                    <Table responsive size="md">
                                        <tbody>
                                            <ResultRows element={this.props.details} who={this.props.search.who}/>
                                        </tbody>
                                    </Table>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    details: state.searchReducer.details,
    loaderStatus: state.searchReducer.detailsLoaderStatus,
    language: state.languageReducer,
    search: state.searchReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        getMedicineDetails: (id) => {
            dispatch(setLoaderStatus(true));
            dispatch(getMedicineDetails(id));
        },
        getMedicalInstitutionDetails: (id) => {
            dispatch(setLoaderStatus(true));
            dispatch(getMedicalInstitutionDetails(id));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);