export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_ACTIVE = 'SET_ACTIVE';
export const SET_FILTER = 'SET_FILTER';
export const SET_LOADER_STATUS = 'SET_LOADER_STATUS';
export const SET_FACILITY_TYPE = 'SET_FACILITY_TYPE';
export const SET_KIND = 'SET_KIND';
export const CHANGE_ACTIVE_TAB = 'CHANGE_ACTIVE_TAB';

export function setFacilityType(facility_type) {
    return {
        type: SET_FACILITY_TYPE,
        payload: facility_type,
    }
}

export function changeActiveTab(activeTab) {
    return {
        type: CHANGE_ACTIVE_TAB,
        payload: activeTab,
    }
}

export function setLanguage(language) {
    return {
        type: SET_LANGUAGE,
        payload: language,
    }
}

export function setLoaderStatus(status){
    return {
        type: SET_LOADER_STATUS,
        payload: status,
    }
}

export function setKind(Kind){
    return {
        type: SET_KIND,
        payload: Kind,
    }
}

export function setActive(number) {
    return {
        type: SET_ACTIVE,
        payload: number,
    }
}

export function setFilter(filter) {
    return {
        type: SET_FILTER,
        payload: filter,
    }
}
